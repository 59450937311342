import React from 'react'
import './Companies.css'


function Companies() {
    return (
    <>
     <div className="c-wrapper">
        <div className="c-container">
            <img src="./equinix.png" alt="img1" />
            <img src="./prologis.png" alt="img2" />
            <img src="./realty.png" alt="img3" />
            <img src="./tower.png" alt="img4" />
        </div>
     </div>
    </>
)
}
export  default Companies